import 'core-js/stable';
import 'regenerator-runtime/runtime';
import React from 'react';
import ReactDOM from "react-dom";
import {browserHistory, IndexRoute, Route, Router} from "react-router";

import 'bootflat/css/bootstrap.min.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'startupbox-react/lib/themes/theme-default';
import 'startupbox-react/lib/themes/theme-mockup';
import 'startupbox-react/lib/themes/theme-mockup-wireframe';
import "./index.css";
import "./dashboard.css";
import "./App.css";
import "./mockup.css";

import App from "./App";
import Initialiser from "./app/Initialiser";

import MainPageLayout from "./app/layouts/MainPageLayout";
import AccountPageLayout from "./app/layouts/AccountPageLayout";
import AdminPageLayout from "./app/layouts/AdminPageLayout";
import PartnerPageLayout from "./app/layouts/PartnerPageLayout";
import ToolPageLayout from "./app/layouts/ToolPageLayout";

import HomePage from "./app/main/HomePage";
import DownloadPage from "./app/main/DownloadPage";

import LogoutPage from "./app/security/LogoutPage";

import AccountProfilePage from "./app/account/AccountProfilePage";
import AccountAvatarPage from "./app/account/AccountAvatarPage";
import AccountPasswordPage from "./app/account/AccountPasswordPage";
import AccountLogoutPage from "./app/account/AccountLogoutPage";

import AdminDashboardUsersPage from "./app/admin/AdminDashboardUsersPage";
import AdminDashboardDevicesPage from "./app/admin/AdminDashboardDevicesPage";
import AdminDashboardSubscriptionsPage from "./app/admin/AdminDashboardSubscriptionsPage";

import FrankieOneMockPage from "./app/frankieone/FrankieOneMockPage";
import FrankieOneSuccessPage from "./app/frankieone/FrankieOneSuccessPage";
import FrankieOneFailurePage from "./app/frankieone/FrankieOneFailurePage";
import FrankieOnePendingPage from "./app/frankieone/FrankieOnePendingPage";

import SettingsPage from "./app/setting/SettingsPage";
import SettingPage from "./app/setting/SettingPage";

// ................................................................................................................................................

import BannersPage from './app/banner/BannersPage';

import CollectiblesPage from './app/collectible/CollectiblesPage';
import CollectiblePage from './app/collectible/CollectiblePage';
import CollectibleActualsPage from './app/collectible/CollectibleActualsPage';

import PartnerMembershipsPage from "./app/partner/PartnerMembershipsPage";
import PartnerMembershipActualsPage from "./app/partner/PartnerMembershipActualsPage";
import PartnerMembershipPage from "./app/partner/PartnerMembershipPage";

import DisbursementsPage from './app/disbursement/DisbursementsPage';
import DisbursementPage from './app/disbursement/DisbursementPage';

import EntitlementsPage from './app/entitlement/EntitlementsPage';

import HubSpotAsyncMessagePage from "./app/hub_spot/HubSpotAsyncMessagePage";
import HubSpotAsyncMessagesPage from "./app/hub_spot/HubSpotAsyncMessagesPage";

import LegalDocumentsPage from "./app/legal_document/LegalDocumentsPage";

import MembershipsPage from "./app/membership/MembershipsPage";
import MembershipPage from "./app/membership/MembershipPage";
import MembershipActualsPage from "./app/membership/MembershipActualsPage";

import PostsPage from "./app/post/PostsPage";

import PartnersPage from "./app/partner/PartnersPage";
import PartnerCollectiblePage from './app/partner/PartnerCollectiblePage';
import PartnerCollectiblesPage from './app/partner/PartnerCollectiblesPage';
import PartnerCollectibleActualsPage from './app/partner/PartnerCollectibleActualsPage';
import PartnerHomePage from './app/partner/PartnerHomePage';
import PartnerHorsesPage from './app/partner/PartnerHorsesPage';
import PartnerHorseImportRunsPage from './app/partner/PartnerHorseImportRunsPage';
import PartnerHorseImportRunPage from './app/partner/PartnerHorseImportRunPage';
import PartnerHorseDetailsPage from './app/partner/PartnerHorseDetailsPage';
import PartnerHorseCollectiblesPage from './app/partner/PartnerHorseCollectiblesPage';
import PartnerHorseCollectiblePage from './app/partner/PartnerHorseCollectiblePage';
import PartnerHorseCollectibleActualsPage from './app/partner/PartnerHorseCollectibleActualsPage';
import PartnerHorsePostsPage from './app/partner/PartnerHorsePostsPage';
import PartnerHorseOwnershipsPage from './app/partner/PartnerHorseOwnershipsPage';
import PartnerHorseTranchePage from './app/partner/PartnerHorseTranchePage';
import PartnerHorseTranchePayoutsPage from './app/partner/PartnerHorseTranchePayoutsPage';
import PartnerHorseTranchePayoutPage from './app/partner/PartnerHorseTranchePayoutPage';
import PartnerHorseMedicalRecordsPage from './app/partner/PartnerHorseMedicalRecordsPage';
import PartnerHorseTrainingRecordsPage from './app/partner/PartnerHorseTrainingRecordsPage';
import PartnerPacksPage from './app/partner/PartnerPacksPage';
import PartnerPackPage from './app/partner/PartnerPackPage';
import PartnerPostsPage from './app/partner/PartnerPostsPage';
import PartnerTeamPage from './app/partner/PartnerTeamPage';

import TransactionsPage from "./app/transaction/TransactionsPage";

import UsersPage from "./app/user/UsersPage";
import UserPage from "./app/user/UserPage";
import UserDeviceDeviceEventsPage from './app/user/UserDeviceEventsPage';
import UserDeviceDeviceInsightsPage from './app/user/UserDeviceInsightsPage';
import UserWalletAppCreditsPage from "./app/user/UserWalletAppCreditsPage";
import UserWalletEntitlementsPage from "./app/user/UserWalletEntitlementsPage";
import UserWalletTransactionsPage from "./app/user/UserWalletTransactionsPage";

import UserImportRunsPage from './app/user/UserImportRunsPage';
import UserImportRunPage from './app/user/UserImportRunPage';

// ................................................................................................................................................

import FundProductSpecificationPage from './specifications/000-fund-product/FundProductSpecificationPage';
import PartnerHorseMembershipsPage from "./app/partner/PartnerHorseMembershipsPage";
import PartnerHorseMembershipPage from "./app/partner/PartnerHorseMembershipPage";
import PartnerHorseMembershipActualsPage from "./app/partner/PartnerHorseMemberActualsPage";
import LegalOwnersPage from "./app/tranche/LegalOwnersPage";

// ................................................................................................................................................

let Redirect = (props) => {
  window.location.href = props.route.to;
  return null;
}

// ................................................................................................................................................

let root = document.getElementById('root');

if (root) {

  // initialise
  Initialiser.init();

  // render application
  ReactDOM.render(

    <Router onUpdate={() => window.scrollTo(0, 0)} history={browserHistory}>

      <Route component={App}>

        <Route path="our-value"                                                                     component={Redirect} to="https://www.btxtechnology.com"/>
        <Route path="our-vision"                                                                    component={Redirect} to="https://www.btxtechnology.com"/>
        <Route path="how-it-works"                                                                  component={Redirect} to="https://www.btxtechnology.com"/>
        <Route path="faq"                                                                           component={Redirect} to="https://www.btxtechnology.com/#faqs"/>
        <Route path="about-us"                                                                      component={Redirect} to="https://www.btxtechnology.com"/>
        <Route path="contact-us"                                                                    component={Redirect} to="https://www.btxtechnology.com"/>
        <Route path="privacy-policy"                                                                component={Redirect} to="https://www.btxtechnology.com/#privacy"/>
        <Route path="terms-and-conditions"                                                          component={Redirect} to="https://www.btxtechnology.com/#terms"/>
        <Route path="product-disclosure-statement"                                                  component={Redirect} to="https://www.btxtechnology.com"/>
        <Route path="support"                                                                       component={Redirect} to="https://www.btxtechnology.com"/>

        <Route                                                                                      component={MainPageLayout} full hideSecondaryNav>
          <Route path="/"                                                                           component={HomePage}/>
          <Route path="/download"                                                                   component={DownloadPage}/>
        </Route>

        <Route                                                                                      component={MainPageLayout}>
          <Route path="logout"                                                                      component={LogoutPage}/>
          <Route path="/frankieone/mock"                                                            component={FrankieOneMockPage}/>
        </Route>

        <Route path="frankie-one"                                                                   component={MainPageLayout}>
          <Route path="mock"                                                                        component={FrankieOneMockPage}/>
          <Route path="success"                                                                     component={FrankieOneSuccessPage}/>
          <Route path="failure"                                                                     component={FrankieOneFailurePage}/>
          <Route path="pending"                                                                     component={FrankieOnePendingPage}/>
        </Route>

        <Route path="account"                                                                       component={AccountPageLayout}>
          <IndexRoute                                                                               component={AccountProfilePage}/>
          <Route path="profile"                                                                     component={AccountProfilePage}/>
          <Route path="avatar"                                                                      component={AccountAvatarPage}/>
          <Route path="password"                                                                    component={AccountPasswordPage}/>
          <Route path="logout"                                                                      component={AccountLogoutPage}/>
        </Route>

        <Route path="admin"                                                                         component={AdminPageLayout}>
          <IndexRoute                                                                               component={AdminDashboardUsersPage}/>
          <Route path="dashboard/users"                                                             component={AdminDashboardUsersPage}/>
          <Route path="dashboard/devices"                                                           component={AdminDashboardDevicesPage}/>
          <Route path="dashboard/subscriptions"                                                     component={AdminDashboardSubscriptionsPage}/>
          <Route path="banners"                                                                     component={BannersPage}/>
          <Route path="collectibles"                                                                component={CollectiblesPage}/>
          <Route path="collectibles/:key"                                                           component={CollectiblePage}/>
          <Route path="collectibles/:key/actuals"                                                   component={CollectibleActualsPage}/>
          <Route path="disbursements"                                                               component={DisbursementsPage}/>
          <Route path="disbursements/:key"                                                          component={DisbursementPage}/>
          <Route path="entitlements"                                                                component={EntitlementsPage}/>
          <Route path="hubspot-async-messages"                                                      component={HubSpotAsyncMessagesPage}/>
          <Route path="hubspot-async-messages/:key"                                                 component={HubSpotAsyncMessagePage}/>
          <Route path="legal-documents"                                                             component={LegalDocumentsPage}/>
          <Route path="memberships"                                                                 component={MembershipsPage}/>
          <Route path="memberships/:key"                                                            component={MembershipPage}/>
          <Route path="memberships/:key/actuals"                                                    component={MembershipActualsPage}/>
          <Route path="posts"                                                                       component={PostsPage}/>
          <Route path="settings"                                                                    component={SettingsPage}/>
          <Route path="settings/:key"                                                               component={SettingPage}/>
          <Route path="partners"                                                                    component={PartnersPage}/>
          <Route path="transactions"                                                                component={TransactionsPage}/>
          <Route path="users"                                                                       component={UsersPage}/>
          <Route path="users/import-runs"                                                           component={UserImportRunsPage}/>
          <Route path="users/import-runs/:userImportRunKey"                                         component={UserImportRunPage}/>
          <Route path="users/:key"                                                                  component={UserPage}/>
          <Route path="users/:key/devices/:deviceKey/events"                                        component={UserDeviceDeviceEventsPage}/>
          <Route path="users/:key/devices/:deviceKey/insights"                                      component={UserDeviceDeviceInsightsPage}/>
          <Route path="users/:key/wallets/:walletKey/app-credits"                                   component={UserWalletAppCreditsPage}/>
          <Route path="users/:key/wallets/:walletKey/entitlements"                                  component={UserWalletEntitlementsPage}/>
          <Route path="users/:key/wallets/:walletKey/transactions"                                  component={UserWalletTransactionsPage}/>
        </Route>

        <Route path="partners/:key"                                                                 component={PartnerPageLayout}>
          <Route path="home"                                                                        component={PartnerHomePage}/>
          <Route path="collectibles"                                                                component={PartnerCollectiblesPage}/>
          <Route path="collectibles/:collectibleKey"                                                component={PartnerCollectiblePage}/>
          <Route path="collectibles/:collectibleKey/actuals"                                        component={PartnerCollectibleActualsPage}/>
          <Route path="horses"                                                                      component={PartnerHorsesPage}/>
          <Route path="horses/import-runs"                                                          component={PartnerHorseImportRunsPage}/>
          <Route path="horses/import-runs/:horseImportRunKey"                                       component={PartnerHorseImportRunPage}/>
          <Route path="horses/:horseKey/details"                                                    component={PartnerHorseDetailsPage}/>
          <Route path="horses/:horseKey/collectibles"                                               component={PartnerHorseCollectiblesPage}/>
          <Route path="horses/:horseKey/collectibles/:collectibleKey"                               component={PartnerHorseCollectiblePage}/>
          <Route path="horses/:horseKey/collectibles/:collectibleKey/actuals"                       component={PartnerHorseCollectibleActualsPage}/>
          <Route path="horses/:horseKey/memberships"                                                component={PartnerHorseMembershipsPage}/>
          <Route path="horses/:horseKey/memberships/:membershipKey"                                 component={PartnerHorseMembershipPage}/>
          <Route path="horses/:horseKey/memberships/:membershipKey/actuals"                         component={PartnerHorseMembershipActualsPage}/>
          <Route path="horses/:horseKey/ownerships"                                                 component={PartnerHorseOwnershipsPage}/>
          <Route path="horses/:horseKey/tranches/:trancheKey"                                       component={PartnerHorseTranchePage}/>
          <Route path="horses/:horseKey/tranches/:trancheKey/payouts"                               component={PartnerHorseTranchePayoutsPage}/>
          <Route path="horses/:horseKey/tranches/:trancheKey/payouts/:payoutKey"                    component={PartnerHorseTranchePayoutPage}/>
          <Route path="horses/:horseKey/posts"                                                      component={PartnerHorsePostsPage}/>
          <Route path="horses/:horseKey/medical-records"                                            component={PartnerHorseMedicalRecordsPage}/>
          <Route path="horses/:horseKey/training-records"                                           component={PartnerHorseTrainingRecordsPage}/>
          <Route path="packs"                                                                       component={PartnerPacksPage}/>
          <Route path="packs/:packKey"                                                              component={PartnerPackPage}/>
          <Route path="posts"                                                                       component={PartnerPostsPage}/>
          <Route path="team"                                                                        component={PartnerTeamPage}/>
          <Route path="memberships"                                                                 component={PartnerMembershipsPage}/>
          <Route path="memberships/:membershipKey"                                                  component={PartnerMembershipPage}/>
          <Route path="memberships/:membershipKey/actuals"                                          component={PartnerMembershipActualsPage}/>

        </Route>

        <Route path="specifications/fund-product"                                                   component={FundProductSpecificationPage}/>

        <Route path="tools"                                                                         component={ToolPageLayout}>
          <IndexRoute                                                                               component={AdminDashboardUsersPage}/>
          <Route path="hubspot-async-messages"                                                      component={HubSpotAsyncMessagesPage}/>
          <Route path="hubspot-async-messages/:key"                                                 component={HubSpotAsyncMessagePage}/>
          <Route path="settings"                                                                    component={SettingsPage}/>
          <Route path="settings/:key"                                                               component={SettingPage}/>
          <Route path="legal-owners"                                                                 component={LegalOwnersPage}/>
        </Route>

      </Route>

    </Router>,

    root

  );

}

